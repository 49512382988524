.swap-page {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 500px;
}
.swap-page svg {
  margin: 30px 0px;
  font-size: 20px;
  align-self: center;
  color: white;
}
.swap-page select {
  min-width: 80px !important;
  font-size: 15px !important;
}
.swap-page .aqua-btn {
  color: white;
  max-width: 200px;
  height: 40px;
  width: 100%;
  font-size: 17px;
  margin-top: 20px;
  border-radius: 5px;
}
