.terms {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 100px auto;
  max-width: 1200px;
  color: white;
  gap: 10px;
}
.terms h1 {
  font-size: 60px;
  margin-bottom: 20px;
  color: rgb(109 45 231);
}
.terms p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-weight: 500;
}
.terms p:first-of-type {
  margin-bottom: 40px;
}
