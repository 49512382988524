.activity-tabs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.activity-tabs p {
  width: 90px;
  text-align: left;
  border-bottom: 2px solid #969696;
  padding-bottom: 2px;
  font-size: 14px;
  cursor: pointer;
  padding-left: 5px;
  color: white;
}
.activity-tabs .active-activity {
  color: rgb(109 45 231);
  border-bottom-color: rgb(109 45 231);
}
.activity-page table {
  width: 100%;
  border-collapse: collapse;
}
.activity-page table th {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #ccc;
  color: white;
  height: 40px;
}
.activity-page table td {
  height: 50px;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #ccc;
  color: white;
}
.wallet-icon {
  color: rgb(109 45 231);
  border-radius: 50%;
  box-sizing: border-box !important;
  padding: 7px;
  border: 1px solid rgb(109 45 231);
  width: 30px;
  height: 30px;
}
.entries {
  align-self: flex-end;
  color: white;
  font-size: 12px;
}
