.transaction-page {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
}
.input-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 500px;
  margin: 10px 0px;
}
.input-div input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  font-size: 16px;
  border: 1px solid rgb(109 45 231);
  padding-left: 10px;
  background-color: transparent;
  color: white;
}
.input-div input:focus {
  outline: none;
}
.input-div label {
  font-size: 14px;
  font-weight: 400;
  color: white;
}
.input-div > div input {
  border: none;
}
.input-div > div {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid rgb(109 45 231);
}
.input-div > div button,
.input-div > div select {
  min-width: 50px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: rgb(109 45 231);
  color: white;
  font-size: 16px;
}

.transaction-page .aqua-btn {
  width: 300px;
  height: 40px;
  font-size: 17px;
  border: 1px solid none;
  margin-top: 20px;
}
.note {
  font-size: 14px;
  max-width: 500px;
  margin-top: 5px;
}
